import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './painting.module.scss'
import ImageViewer from '../components/ImageViewer.js'

export default ({ data, pageContext }) => {
  const {
    Title,
    Height,
    Width,
    Sold,
    Medium,
    gallery,
    Image,
  } = data.strapiPainting

  const { prev, next, collection, number, total } = pageContext

  let availability
  if (!Sold && gallery && gallery.Name) {
    availability = (
      <Link className={styles.link} to="/Galleries">
        Available at {gallery.Name}
      </Link>
    )
  } else if (!Sold) {
    availability = 'Available'
  } else if (Sold) {
    availability = 'Sold'
  }

  return (
    <ImageViewer
      returnLink={collection ? collection.replace(/\s/g, '') : '/Paintings'}
      prevLink={prev}
      nextLink={next}
      collection={collection}
      number={number}
      total={total}
    >
      <div className={styles.imgwrap}>
        <Img
          alt={Title}
          className={styles.painting}
          imgStyle={{
            objectFit: 'contain',
          }}
          fluid={Image.childImageSharp.fluid}
        />
      </div>
      <figcaption className={styles.caption}>
        <div className={styles.title}>{Title}</div>
        <div className={styles.details}>
          <div className={styles.medium}>{Medium.replace(/_/g, ' ')}</div>
          <div className={styles.size}>
            {Height} × {Width}
          </div>
          <div className={styles.availability}>{availability}</div>
        </div>
      </figcaption>
      {!Sold ? (
        <Link to="/Contact" state={{ subject: Title }} className={styles.email}>
          <button className={styles.button}>
            Contact the artist about this work
          </button>
        </Link>
      ) : null}
    </ImageViewer>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiPainting(id: { eq: $id }) {
      id
      Title
      Height
      Width
      Sold
      Medium
      gallery {
        Name
      }
      grouping {
        Title
      }
      Image {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
