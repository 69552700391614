import React from 'react'
import { Link } from 'gatsby'
import styles from './imageviewer.module.scss'
import SingleArrow from './icons/SingleArrowSVG.js'

export default props => {
  let nextLink =
    props.collection && props.nextLink
      ? props.collection + '/' + props.nextLink
      : props.nextLink

  let prevLink =
    props.collection && props.prevLink
      ? props.collection + '/' + props.prevLink
      : props.prevLink

  return (
    <div className={styles.imageviewer}>
      <Link className={styles.return} to={'/' + props.returnLink}>
        <SingleArrow direction="left" height="25px" width="25px" />
        Return to the collection
      </Link>
      <div className={styles.count}>
        {props.number} / {props.total}
      </div>
      {props.children}
      {prevLink ? (
        <Link className={styles.prev} to={'/' + prevLink}>
          <SingleArrow direction="left" height="35px" width="35px" />
        </Link>
      ) : null}
      {nextLink ? (
        <Link className={styles.next} to={'/' + nextLink}>
          <SingleArrow width="35px" height="35px" />
        </Link>
      ) : null}
    </div>
  )
}
