import React from 'react'
import styles from './singlearrowsvg.module.scss'

const SingleArrow = props => {
  const dir = props.direction
  let deg
  if (dir === 'left') {
    deg = 180
  } else if (dir === 'up') {
    deg = -90
  } else if (dir === 'down') {
    deg = 90
  } else {
    deg = 0
  }

  return (
    <svg
      height={props.height}
      width={props.width}
      style={{ transform: `rotate(${deg}deg)` }}
      className={styles.singlearrow}
      viewBox="-2 -2 16 20"
    >
      <g transform="translate(0,-1036.3622)">
        <g transform="matrix(1.0664186,0,0,1.0664186,58.689952,71.970494)">
          <g transform="translate(-56.000005,-133)">
            <path d="m 11.280275,1044.8292 -4.9706383,-4.6885 -0.6556703,0.619 4.3131369,4.0695 -4.3131369,4.0696 0.6556703,0.619 4.9706383,-4.6886 z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SingleArrow
